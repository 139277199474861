import axios from "axios";
import Link from "next/link";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

export default function PriceSlider({ backgroundColor }) {
	const { t } = useTranslation();
	const [frequency, setFrequency] = useState([]);
	const [economies, setEconomies] = useState([]);
	const [lang, setLang] = useState("");
	const [slider1Value, setSlider1Value] = useState(2);
	const [slider2Value, setSlider2Value] = useState(1);
	const [economyValue, setEconomyValue] = useState(3.8);
	const [economylabel, setEconomyLabel] = useState(3);
	const [frequencyValue, setFrequencyValue] = useState(2);
	const economyRef = useRef();
	const frequencyRef = useRef();
	const minEconomy = 0;
	const maxEconomy = 4;
	const minFrequency = 0;
	const maxFrequency = 2;

	useEffect(() => {
		const topLevelDomain = window.location.hostname.split(".").pop();
		setLang(topLevelDomain === "es" ? "es" : "fr");
	}, []);

	useEffect(() => {
		Promise.all([
			axios.get(
				`${process.env.NEXT_PUBLIC_STRAPI_URL}/api/person-economies?locale=${
					lang === "es" ? "es" : "fr-FR"
				}`
			),
			axios.get(
				`${process.env.NEXT_PUBLIC_STRAPI_URL}/api/frequency-monthlies?locale=${
					lang === "es" ? "es" : "fr-FR"
				}`
			),
		])
			.then((response) => {
				setEconomies(response[0].data.data);
				setFrequency(response[1].data.data);
			})
			.catch((error) => {
				console.log(error);
			});
	}, [lang]);

	if (!economies && !frequency) {
		return <h1>Loading...</h1>;
	}

	return (
		<div
			style={{ backgroundColor: backgroundColor }}
			className="price_slider_container"
		>
			<div>
				<h3 className="slider_title">{t("price.slider.title")}</h3>
				<h4>{t("price.slider.people.title")}</h4>
				<input
					style={{
						backgroundSize:
							((parseInt(slider1Value) - minEconomy) * 100) /
								(maxEconomy - minEconomy) +
							"% 100%",
					}}
					ref={economyRef}
					type="range"
					min={0}
					max={4}
					list="tickmarks_economy"
					value={slider1Value}
					onChange={(e) => {
						setSlider1Value(e.target.value);
						setEconomyValue(economies[e.target.value].attributes.value);
						setEconomyLabel(Number(economies[e.target.value].attributes.label));
					}}
				/>
				<div id="tickmarks_economy">
					{economies.map((e) => (
						<span
							value={e.attributes.value}
							label={e.attributes.label}
							key={e.attributes.label}
						>
							{e.attributes.label}
						</span>
					))}
				</div>
				<br />
				<h4>{t("price.slider.frequency.title")}</h4>
				<input
					ref={frequencyRef}
					type="range"
					style={{
						backgroundSize:
							((parseInt(slider2Value) - minFrequency) * 100) /
								(maxFrequency - minFrequency) +
							"% 100%",
					}}
					min={0}
					max={2}
					list="tickmarks_frequency"
					value={slider2Value}
					onChange={(e) => {
						setSlider2Value(e.target.value),
							setFrequencyValue(frequency[e.target.value].attributes.value);
					}}
				/>
				<div id="tickmarks_frequency">
					{frequency.map((f) => (
						<span
							value={f.attributes.value}
							label={f.attributes.label}
							key={f.attributes.label}
						>
							{f.attributes.label}
						</span>
					))}
				</div>
			</div>
			<div className="price_container">
				<span className="price">
					{Math.floor(
						parseFloat(economyValue) *
							parseInt(economylabel) *
							parseInt(frequencyValue) *
							12
					)}
					€
				</span>
				<p className="price_description">
					{t("price.slider.description", { price: "300" })}
				</p>
				<Link className="price_button" href="/checkout">
					{t("price.slider.button")}
				</Link>
			</div>
		</div>
	);
}
