import Link from "next/link";
import {useMemo} from "react";

/**
 * Component to render a `<a>` element if the link targets an external URL, and a `<Link>` component otherwise
 */
const SmartLink = ({ href, children, className, ...props }) => {
    const isExternal = useMemo(() => {
        if (!href) return false;
        return href.startsWith('http') || href.startsWith('https') || href.startsWith('//');
    }, [href]);

    if (isExternal) {
        return (
            <a
                href={href}
                className={className}
                target="_blank"
                rel="noopener noreferrer"
                {...props}
            >
                {children}
            </a>
        );
    }

    return (
        <Link href={href} className={className} {...props}>
            {children}
        </Link>
    );
};

export default SmartLink;