import { useAtomValue } from "jotai";
import { localeAtom } from "../../utils/helpers/store";
import { useEffect } from "react";

export default function Trustpilot({ className }) {
	const local = useAtomValue(localeAtom);

	const trustpilotData = {
		fr: {
			dataLocale: "fr-FR",
			dataBusinessUnitId: "63ecd8b58722793aa12cb53a",
		},
		es: {
			dataLocale: "es-ES",
			dataBusinessUnitId: "63fd05894493539d7dc81312",
		},
	};

	useEffect(() => {
		var aScript = document.createElement("script");
		aScript.type = "text/javascript";
		aScript.src =
			"//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js";
		aScript.async = "true";
		document.head.appendChild(aScript);
		aScript.onload = function () {
			var trustbox = document.getElementById("trustbox");
			window.Trustpilot.loadFromElement(trustbox);
		};
	}, []);

	return (
		<div className={className}>
			<div
				id="trustbox"
				className="trustpilot-widget"
				data-locale={
					trustpilotData[local]?.dataLocale || trustpilotData.fr.dataLocale
				}
				data-template-id="5419b6ffb0d04a076446a9af"
				data-businessunit-id={
					trustpilotData[local]?.dataBusinessUnitId ||
					trustpilotData.fr.dataBusinessUnitId
				}
				data-style-height="20px"
				data-style-width="100%"
				data-theme="light"
				data-font-family="Poppins"
			></div>
		</div>
	);
}
