import Link from "next/link";
import React from "react";
import dayjs from "dayjs";

dayjs.locale("fr");
export default function SingleArticle({ data, link }) {
  return (
    <div key={data?.id} className="recommendation_article">
      <div>
        {data.attributes.preview_image.data != null ? (
          <img src={data.attributes?.preview_image?.data[0]?.attributes?.url} />
        ) : (
          ""
        )}
        <span>{data.attributes.tags?.data[0]?.attributes.name}</span>
        <Link href={`/${link}/${data?.attributes?.slug}`}>
          <h4>{data?.attributes?.title}</h4>
        </Link>
        <div className="article_info">
          <span>{data?.attributes?.author}</span>
          <span>
            {dayjs(data.attributes?.publishedAt).format("DD MMMM YYYY")}
          </span>
        </div>
        <p>{data?.attributes?.description}</p>
      </div>
    </div>
  );
}
