import axios from "axios";
import Link from "next/link";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SingleArticle from "../SingleArticle/SingleArticle";

export default function LatestArticles() {
  const [lang, setLang] = useState("");
  const [articles, setArticles] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    const topLevelDomain = window.location.hostname.split(".").pop();
    setLang(topLevelDomain === "es" ? "es" : "fr");
  }, []);
  useEffect(() => {
    const fetchLatestPost = async () => {
      try {
        const { data } = await axios.get(
          `${
            process.env.NEXT_PUBLIC_STRAPI_URL
          }/api/blog-posts?sort=publishedAt:desc&populate=*&pagination[limit]=3&locale=${
            lang === "es" ? lang : "fr-FR"
          }`
        );
        setArticles(data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchLatestPost();
  }, [lang]);
  return (
    <div className="latest_article_container">
      <div className="latest_article_description">
        <h2>{t("latest.article.title")}</h2>
        <p>{t("latest.article.description")}</p>
        <Link href="/blog" className="latest_article_button">
          {t("latest.article.button")}
        </Link>
      </div>
      <div className="recommendation_articles">
        {articles && (
          <>
            {articles?.data?.map((a) => {
              return <SingleArticle data={a} key={a.id} link="blog" />;
            })}
          </>
        )}
      </div>
    </div>
  );
}
